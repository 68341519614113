// extracted by mini-css-extract-plugin
export var academy = "page-module--academy--ltY66";
export var history = "page-module--history--ghIHS";
export var history__content = "page-module--history__content--ZAMfy";
export var history__header = "page-module--history__header--VZUNk";
export var history__next = "page-module--history__next--QD-uH";
export var history__prev = "page-module--history__prev--CV6e9";
export var history__slide = "page-module--history__slide--TjTG9";
export var history__slider = "page-module--history__slider--xbTNC";
export var history__years = "page-module--history__years--tvKoX";
export var mob = "page-module--mob--clbO+";
export var nav = "page-module--nav--2W4KX";
export var orga = "page-module--orga--BqZyx";
export var orga__wrapper = "page-module--orga__wrapper--pA+2E";
export var structure = "page-module--structure--kWsB6";
export var structure__list = "page-module--structure__list--mDWd4";
export var values = "page-module--values--0NmYQ";
export var work = "page-module--work--YX5As";